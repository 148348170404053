<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Поставки</div>
                <div class="page__desc">Список поставок на склады</div>
            </div>
        </div>

        <div class="page__content">
            <div class="table-responsive">
                <table class="table table-striped">
                    <tr>
                        <th>#</th>
                        <th>Магазин</th>
                        <th>Продукт</th>
                        <th>Количество (Остаток)</th>
                        <!--<th>Срок годности</th>-->
                        <th>Дата поставки</th>
                        <th></th>
                    </tr>
                    <tr v-for="supply in supplies"
                        v-bind:key="supply.id">

                        <td>{{ supply.id }}</td>
                        <td>{{ stores[supply.store_id].address }}</td>
                        <td>{{ products[supply.product_id].title }}</td>
                        <td>{{ supply.quantity_received }} ({{ supply.quantity_actual }}) {{ products[supply.product_id].packaging_type }}</td>
                        <!--<td><abbr :title="supply.expired_at | moment('DD MMM YYYY HH:mm')">{{ supply.expired_at | moment("DD MMM YYYY") }}</abbr></td>-->
                        <td><abbr :title="supply.created_at | moment('DD MMM YYYY HH:mm')">{{ supply.created_at | moment("DD MMM YYYY") }}</abbr></td>
                        <td>
                            <nobr>
                                <router-link
                                        :to="{ name: 'StoreWriteOffsCreate', query: {supply_id: supply.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-outline-danger"
                                        value="Списание"/>
                                <router-link
                                        :to="{ name: 'StoreSuppliesUpdate', params: {id: supply.id } }"
                                        tag="input"
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        value="Изменить"/>
                            </nobr>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreSupplies',
        computed: {
            supplies() {
                return this.$store.state.supplies.supplies.supplies
            },
            stores() {
                return this.$store.state.supplies.supplies.stores
            },
            products() {
                return this.$store.state.supplies.supplies.products
            }
        },
        methods: {
            ...mapActions([
                'storeSupplies'
            ]),
            async getSupplies() {
                await this.storeSupplies()
            }
        },
        created() {
            this.getSupplies();
        }
    }
</script>

<style lang="scss" scoped>
    .btn {
        margin-right: 10px;
    }
</style>